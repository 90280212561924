<template>
  <div>
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan menghapus lowongan?`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => {
            showRemove = null
          },
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            remove(showRemove)
            showRemove = null
          },
        },
      ]"
    />
    <v-row>
      <v-col cols="6">
        <molecules-summary-card
          icon="text-box"
          :title="`${summaryMonths || 0} Lowongan`"
          :desc="`Ditemukan Berdasarkan Hasil Pencarian`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="6">
        <molecules-summary-card
          icon="text-box-multiple"
          :title="`${getTotalLowongan || $store.state.vacancy.count || 0} Total Lowongan`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="!getTotalLowongan && reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter">
      <atoms-text class="pa-2"><v-icon left>mdi-magnify</v-icon><span>Pencarian</span></atoms-text>
      <v-row justify="space-between" align="center">
        <v-col cols="12" md="8">
          <atoms-text-field
            label="Cari Lowongan, ketikkan judul, provinsi, kota dll.."
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" md="1" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text">Cari</atoms-button>
        </v-col>
        <v-col cols="12" md="1" class="d-flex justifyx-space-between">
          <atoms-button @click="mounted()" style="width: 100%" class="red white--text">Reset</atoms-button>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-space-between">
          <atoms-button @click="showSubmit = true" style="width: 100%" class="primary"
            ><v-icon left>mdi-plus</v-icon>Tambah Lowongan</atoms-button
          >
        </v-col>

        <v-col cols="12" md="3">
          <atoms-text-field label="Minimum Salary" v-model.trim="salary.min" prefix="Rp." />
        </v-col>
        <v-col cols="12" md="3">
          <atoms-text-field label="Maximum Salary" v-model.trim="salary.max" prefix="Rp." />
        </v-col>
        <v-col cols="12" md="3">
          <atoms-text-field label="Status">
            <v-combobox
              class="rounded-lg"
              placeholder="Status"
              prepend-inner-icon="mdi-filter"
              :items="statuses.map((x) => x.title)"
              v-model="status"
              @change="loadData"
              hide-details
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            /> </atoms-text-field
        ></v-col>

        <v-col cols="12" md="3">
          <atoms-text-field label="Tipe Gaji">
            <v-combobox
              class="rounded-lg"
              placeholder="Filters"
              prepend-inner-icon="mdi-filter-variant"
              :items="$salaryStatus"
              v-model="filter"
              @change="loadData"
              hide-details
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>

        <v-col cols="12" md="8">
          <v-row align="center" dense no-gutters>
            <!-- <v-col cols="12" md="2">
              <atoms-button
                class="primary"
                style="width: 100%"
                @click="
                  () => {
                    sort = sort === constant[0] ? constant[1] : constant[0]
                    getVacancy()
                  }
                "
                ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
                >Sort: {{ sort }}</atoms-button
              >
            </v-col> -->
            <v-col cols="12" md="auto">
              <v-row dense align="center" justify="end">
                <v-col cols="12" md="auto">
                  <atoms-text-field label="Stage" :noLabel="true">
                    <v-combobox
                      class="rounded-lg"
                      placeholder="Sorter"
                      prepend-inner-icon="mdi-sort"
                      :items="sorters.map((x) => x.title)"
                      v-model="sorter"
                      @change="getVacancy()"
                      hide-details
                      dense
                      solo
                      flat
                      required
                      :style="{
                        'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                      }"
                    />
                  </atoms-text-field>
                </v-col>
                <v-col cols="12" md="auto">
                  <atoms-button
                    class="primary"
                    :style="{
                      width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                    }"
                    @click="
                      () => {
                        sort = sort === constant[0] ? constant[1] : constant[0]
                        getVacancy()
                      }
                    "
                    ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
                    >Sort: {{ sort }}</atoms-button
                  ></v-col
                >
              </v-row>
            </v-col>
            <v-col cols="12" md="2">
              <atoms-button
                type="submit"
                style="width: 100%"
                class="teal lighten-5 teal--text text--darken-2 my-3 ml-3"
                @click.prevent="exportXlsx"
                ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX
                <span v-if="loadingXlsxPercentage">{{ loadingXlsxPercentage }}%</span></atoms-button
              >
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="grow d-flex justify-center justify-md-start align-center">
          <v-checkbox v-model="enableDate" hide-spin-buttons @change="getVacancy" /><atoms-text
            >Filter dengan tanggal dibuat</atoms-text
          >
        </v-col>

        <!-- date -->
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableDate" class="py-0">
            <v-row dense>
              <v-col cols="12" md="6">
                <atoms-date-picker
                  label="Dari Tanggal"
                  :modal="from.modal"
                  :value="from.value"
                  @change="
                    (modal, value) => {
                      from.modal = modal
                      from.value = value
                    }
                  "
                />
              </v-col>
              <v-col cols="12" md="6">
                <atoms-date-picker
                  label="Sampai Tanggal"
                  :modal="to.modal"
                  :value="to.value"
                  @change="
                    (modal, value) => {
                      to.modal = modal
                      to.value = value
                    }
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>

        <v-col
          v-if="$store.state.vacancy.tags && $store.state.vacancy.tags.length > 0 && !mainLoading && !reportLoading"
          cols="12"
          class="overflow-auto"
          style="white-space: nowrap"
        >
          <v-hover v-for="tag in $store.state.vacancy.tags || []" :key="tag._id">
            <v-chip
              slot-scope="{ hover: tagHover }"
              :class="[
                'mx-2',
                tagHover || (tagsFilter && tagsFilter.length > 0 && tagsFilter.includes(tag._id))
                  ? 'primary white--text'
                  : '',
              ]"
              @click="
                () => {
                  if (tagsFilter && tagsFilter.length > 0 && tagsFilter.includes(tag._id)) {
                    tagsFilter = tagsFilter.filter((item) => item !== tag._id)
                  } else {
                    tagsFilter = [...(tagsFilter || []), tag._id]
                  }
                  getVacancy()
                }
              "
              style="display: inline-block; width: fit-content !important"
            >
              {{ tag._id }} ({{ tag.total }})
            </v-chip>
          </v-hover>
        </v-col>
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.vacancy.data && $store.state.vacancy.data.length > 0">
          <!-- <atoms-text class="pa-2"
            ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
            ditemukan</atoms-text
          > -->
          <div
            v-for="({ title, status, ...rest }, i) in $store.state.vacancy.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col cols="12" md="4">
                <atoms-title :h3="true" class="font-weight-bold"
                  >{{ $toCapitalize(title || 'Judul tidak dicantumkan') }}
                </atoms-title>
                <atoms-text class="primary--text"
                  >{{ status || '-' }}
                  <span v-if="rest.useHeadHunter" class="red--text"> - (HeadHunter)</span></atoms-text
                >
              </v-col>
              <v-col cols="12" md="auto">
                <!-- <atoms-image
                  :width="150"
                  :height="100"
                  :enableZoom="true"
                  :src="picture"
                  class="rounded-lg background"
                /> -->
                <atoms-text>Provinsi {{ rest.province || 'Tidak dicantumkan' }}</atoms-text>
                <atoms-text>Kota/Daerah {{ rest.city || 'Tidak dicantumkan' }}</atoms-text>
              </v-col>

              <v-col align="start">
                <atoms-text
                  >Dibuat pada
                  {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}
                  <!-- <span v-if="rest.createdByData">
                    oleh
                    {{ rest.createdByData.nickname || "-" }}
                  </span> -->
                </atoms-text>
                <atoms-text class="primary--text"
                  >Disunting pada
                  {{ $moment(rest._updatedDate).format('dddd, DD MMMM YYYY') }}
                  <!-- <span v-if="rest.updatedByData"
                    >oleh {{ rest.updatedByData.nickname || "-" }}</span
                  > -->
                </atoms-text>
              </v-col>
              <!-- <v-col align="start">
                
              </v-col> -->
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg" min-width="200px">
                    <v-list-item
                      link
                      @click="$router.push(`/lowongan/detail/${rest._id}`)"
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-view-list-outline</v-icon>
                      <v-list-item-title class="px-2">Detail</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showEdit = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-pencil</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!rest.isUseHeadHunter"
                      link
                      @click="showAssignHeadHunter = { title, status, ...rest }"
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-account</v-icon>
                      <v-list-item-title class="px-2"
                        >{{ rest.idHeadHunter ? 'Edit' : 'Assign' }} Head Hunter</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="$store.state.user.roles.includes($roles.Super_Admin)"
                      @click="showRemove = rest._id"
                      class="rounded-lg px-4 text-center red white--text"
                    >
                      <v-icon left dark>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <atoms-container class="px-0">
              <v-divider v-if="$vuetify.breakpoint.smAndDown" class="py-2"></v-divider>
              <br />
              <atoms-text
                >- PIC Client :
                {{ (rest.client && rest.client.contactPerson && rest.client.contactPerson.name) || '-' }}</atoms-text
              >
              <atoms-text
                v-if="rest.client && rest.client.contactPerson && rest.client.contactPerson.email"
                :href="`mailto:${rest.client.contactPerson.email}`"
                target="_blank"
                >- PIC Email : {{ rest.client.contactPerson.email || '-' }}</atoms-text
              >
              <atoms-text
                >- PIC Phonenum :
                {{ (rest.client && rest.client.contactPerson && rest.client.contactPerson.phone) || '-' }}</atoms-text
              >
              <br />
              <v-divider class="py-2"></v-divider>
              <atoms-text class="orange--text"
                >- Salary : {{ $idCurrency(rest.salary || 0) || '-' }} / {{ rest.salaryStatus }}</atoms-text
              >

              <v-menu
                v-if="rest.client && rest.client.name"
                :open-on-hover="!$vuetify.breakpoint.smAndDown"
                offset-y
                :min-width="$vuetify.breakpoint.smAndDown ? '250px' : '500px'"
                class="rounded-xl"
              >
                <template v-slot:activator="{ on, attrs }">
                  <atoms-text v-on="on" v-bind="attrs" class="primary--text text-uppercase text-decoration-underline">
                    - Klien : {{ rest.client.name }}
                  </atoms-text>
                </template>
                <v-list disabled nav dense class="pa-4">
                  <v-hover>
                    <v-list-item
                      @click="
                        $router.push({
                          path: '/clients/review',
                          query: {
                            _id: rest.client._id,
                          },
                        })
                      "
                      slot-scope="{ hover: client_profile_hover }"
                      :class="['mb-4 pa-2', client_profile_hover ? 'primary lighten-5' : '']"
                    >
                      <v-list-item-avatar
                        color="background"
                        size="50"
                        class="rounded-full"
                        :style="{
                          cursor: 'pointer',
                        }"
                      >
                        <v-img v-if="rest.client.logo" :src="rest.client.logo" aspect-ratio="1" />
                        <v-icon v-else> mdi-domain </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title
                        ><atoms-text class="font-weight-bold">{{ rest.client.name || 'Client' }}</atoms-text>
                        <atoms-text>{{
                          (rest.client.contactPerson && rest.client.contactPerson.email) || '-'
                        }}</atoms-text>
                        <atoms-text :span="true" class="primary--text text-decoration-line-through"
                          >Klik untuk lihat detail</atoms-text
                        >
                      </v-list-item-title>
                    </v-list-item>
                  </v-hover>
                </v-list>
              </v-menu>

              <atoms-text>- Penulis : {{ (rest.createdByData && rest.createdByData.nickname) || '-' }}</atoms-text>
              <atoms-text
                >- Disunting Oleh : {{ (rest.updatedByData && rest.updatedByData.nickname) || '-' }}</atoms-text
              >
              <div v-if="rest.headHunter" class="d-flex align-center">
                <atoms-text>- HeadHunter : </atoms-text>
                <v-chip class="px-2 mx-2">
                  <v-avatar
                    v-if="rest.headHunter.user"
                    color="accent"
                    class="rounded-pill"
                    :style="{
                      cursor: 'pointer',
                    }"
                  >
                    <atoms-image
                      v-if="rest.headHunter.user.picture"
                      aspect-ratio="1"
                      :src="rest.headHunter.user.picture || `/lazy-img.webp`"
                    />
                    <v-icon v-else> mdi-account </v-icon>
                  </v-avatar>
                  <atoms-text>{{ rest.headHunter.fullName }}</atoms-text>
                </v-chip>
              </div>

              <div class="d-flex align-center mt-5">
                <atoms-text>
                  <v-icon small>mdi-account-multiple</v-icon> Jumlah pekerja yang berminat :
                  {{ (rest.workerIds && rest.workerIds.length) || '0' }}</atoms-text
                ><atoms-button @click="$router.push(`/lowongan/detail/${rest._id}`)" small class="primary--text ml-3"
                  >Lihat detail</atoms-button
                >
              </div>
              <!-- <atoms-text class="mt-5"> {{ rest.excerpt || "" }}</atoms-text> -->
            </atoms-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.vacancy.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada lowongan yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <v-slide-x-reverse-transition>
      <organism-vacancy-assign-head-hunter
        v-if="showAssignHeadHunter"
        :vacancy="showAssignHeadHunter"
        @close="
          () => {
            showAssignHeadHunter = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-vacancy-submit
        v-if="showSubmit || showEdit"
        :id="showEdit"
        @close="
          () => {
            showSubmit = false
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
const sortConstant = [
  {
    title: 'Tanggal Dibuat',
    value: '_createdDate',
  },
  {
    title: 'Jumlah Pelamar',
    value: 'totalPelamar',
  },
]
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      showSubmit: false,
      showAssignHeadHunter: null,
      showRemove: null,
      showEdit: null,
      reportLoading: false,
      mainLoading: false,
      summaryMonths: 0,
      loadingXlsxPercentage: 0,
      salary: {
        min: '',
        max: '',
      },
      sort: '',
      constant: ['Desc', 'Asc'],
      filter: 'per month',
      enableDate: false,
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      status: null,
      statuses: [
        {
          title: 'Semua',
          value: {
            status: undefined,
          },
        },
        {
          title: this.$postStatus[0], // Published
          value: {
            status: this.$postStatus[0],
          },
        },
        {
          title: this.$postStatus[1], // Drafted
          value: {
            status: this.$postStatus[1],
          },
        },
        {
          title: this.$postStatus[2], // Closed
          value: {
            status: this.$postStatus[2],
          },
        },
      ],
      filters: [
        {
          title: 'Semua',
          value: {
            salaryStatus: undefined,
          },
        },
        {
          title: this.$salaryStatus[0], // Published
          value: {
            salaryStatus: { $regex: this.$salaryStatus[0] || '', $options: 'i' },
          },
        },
        {
          title: this.$salaryStatus[1], // Drafted
          value: {
            salaryStatus: { $regex: this.$salaryStatus[1] || '', $options: 'i' },
          },
        },
      ],

      tagsFilter: null,
      sorter: '',
      sort: '',
      constant: ['Desc', 'Asc'],
      sorters: sortConstant?.map((x) => {
        return {
          title: this.$toCapitalize(x.title),
          value: x.value,
        }
      }),
    }
  },
  watch: {
    'salary.min': function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => {
        this.salary.min = result
        this.getVacancy()
      })
    },
    'salary.max': function (newValue) {
      const result = newValue ? this.$separator(newValue) : ''
      this.$nextTick(() => {
        this.salary.max = result
        this.getVacancy()
      })
    },
    page: {
      handler() {
        this.getVacancy()
      },
      deep: true,
    },
  },
  async mounted() {
    this.mounted()
  },
  computed: {
    getTotalLowongan() {
      return Number(window.localStorage.getItem('total-lowongan')) || 0
    },
  },
  methods: {
    async mounted() {
      this.sort = this.constant[0]
      this.sorter = this.$toCapitalize(sortConstant[0].title)
      this.filter = this.filters[0].title
      this.status = this.statuses[0].title
      if (this.$route?.query?.edit) {
        await this.getVacancy(this.$route?.query?.edit)
        const id = this.$store.state.vacancy.data?.[0]?._id
        if (id) {
          this.showEdit = id
        } else {
          this.loadData()
        }
      } else {
        this.loadData()
      }
    },
    loadData() {
      this.page = 1
      this.getVacancy()
      this.getSummary()
      this.$vuetify.goTo(0)
    },
    async remove(payload) {
      this.$store.commit('SET', { loading: true })
      try {
        if (payload) {
          await this.$store.dispatch('vacancy/remove', payload)
          this.$showDialog({
            title: 'Berhasil',
            body: `Lowongan berhasil dihapus!`,
          })
          this.loadData()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    getQuery() {
      return {
        tags:
          this.tagsFilter?.length > 0
            ? {
                $in: this.tagsFilter,
              }
            : undefined,
        title: { $regex: this.term || '', $options: 'i' },
        ...(this.salary?.min
          ? Object.assign(
              {},
              {
                salary: {
                  $gte: Number(this.$removeSeparator(this.salary?.min || 0)),
                  $lte: Number(this.$removeSeparator(this.salary?.max || Number.MAX_VALUE)),
                },
              },
            )
          : {}),
        // ...(this.filter && this.filter.length > 0
        //   ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
        //   : []),
        ...(this.filter ? this.filters?.find((y) => y.title === this.filter)?.value : {}),
        // ...(this.status && this.status.length > 0
        //   ? Object.assign({}, ...this.status?.map((x) => this.statuses?.find((y) => y.title === x)?.value))
        //   : []),
        ...(this.status ? this.statuses?.find((y) => y.title === this.status)?.value : {}),
        _createdDate: this.enableDate
          ? {
              $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
              $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
            }
          : { $exists: true },
        pipeline: [
          // {
          //   $sort: {
          //     _createdDate: this.sort === this.constant[0] ? -1 : 1,
          //   },
          // },
          ...this.$store.state.vacancy?.defaultPipeline,
          {
            $sort: Object.assign(
              {},
              (() => {
                const finder = this.sorters?.find((x) => x.title === this.sorter)?.value
                return finder
                  ? {
                      [finder]: this.sort === this.constant[0] ? -1 : 1,
                    }
                  : {
                      _createdDate: this.sort === this.constant[0] ? -1 : 1,
                    }
              })(),
            ),
          },
        ],
      }
    },
    async getSummary() {
      try {
        this.reportLoading = true
        await this.$store.dispatch('vacancy/getSummary')
        if (this.$store.state.vacancy.count) {
          window.localStorage.setItem('total-lowongan', this.$store.state.vacancy.count || 0)
        }
        await this.$store.dispatch('vacancy/getSummaryTags')
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getVacancy(slug) {
      this.mainLoading = true
      const query = {
        slug,
        ...this.getQuery(),
      }
      try {
        await this.$store.dispatch('vacancy/getAll', {
          custom: query,
          page: this.page,
          limit: this.limit,
        })

        this.$store
          .dispatch('vacancy/getSummary', {
            returnOnly: true,
            custom: query,
          })
          .then((result) => {
            this.summaryMonths = result?.count
          })

        console.log()
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async exportXlsx() {
      const isConfirmed = confirm('Yakin mau ekspor?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const filter = this.getQuery()
        const realm = await this.$realm()
        const vacancies = await realm.functions.getVacancies(JSON.stringify(filter))
        const columns = [
          {
            label: 'Nama Perusahaan',
            value: 'Nama Perusahaan',
          },
          {
            label: 'Nama PIC Perusahaan',
            value: 'Nama PIC Perusahaan',
          },
          {
            label: 'Email PIC Perusahaan',
            value: 'Email PIC Perusahaan',
          },
          {
            label: 'Nomor Telepon PIC Perusahaan',
            value: 'Nomor Telepon PIC Perusahaan',
          },
          {
            label: 'Judul Lowongan',
            value: 'Judul Lowongan',
          },
          {
            label: 'Nama Pelamar',
            value: 'Nama Pelamar',
          },
          {
            label: 'No KTP Pelamar',
            value: 'No KTP Pelamar',
          },
          {
            label: 'Email Pelamar',
            value: 'Email Pelamar',
          },
          {
            label: 'No Telepon Pelamar',
            value: 'No Telepon Pelamar',
          },
          {
            label: 'Alamat Pelamar',
            value: 'Alamat Pelamar',
          },
          {
            label: 'Keterampilan Pelamar',
            value: 'Keterampilan Pelamar',
          },
          {
            label: 'Pengalaman Pelamar',
            value: 'Pengalaman Pelamar',
          },
        ]
        const content = vacancies.flatMap((item) => {
          const client = item.client
          const workers = item.workers
          return workers.map((worker) => ({
            'Nama Perusahaan': client?.name,
            'Nama PIC Perusahaan': client?.contactPerson?.name,
            'Email PIC Perusahaan': client?.contactPerson?.email,
            'Nomor Telepon PIC Perusahaan': client?.contactPerson?.phone,
            'Judul Lowongan': item?.title,
            'Nama Pelamar': worker?.fullName,
            'No KTP Pelamar': worker?.idNumber,
            'Email Pelamar': worker?.email,
            'No Telepon Pelamar': worker?.phone,
            'Alamat Pelamar': worker?.address?.streetAddress,
            'Keterampilan Pelamar': worker?.detail?.skills
              ?.map((skill) => skill?.name || '')
              ?.filter((val) => val)
              ?.join(', '),
            'Pengalaman Pelamar': worker?.detail?.experiences
              ?.map((exp) => exp?.experience || '')
              ?.filter((val) => val)
              ?.join(', '),
          }))
        })
        let data = [
          {
            sheet: 'Vacancies',
            columns,
            content,
          },
        ]
        let settings = {
          fileName: 'Vacancies',
        }
        this.$xlsx(data, settings)
      } catch (err) {
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
